/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/


.page-header {
	width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    &::after {
    	content: '';
    	background-image: linear-gradient(180deg, hsla(0,0%,0%,0.78) 0%, hsla(0,0%,0%,0.7432362139800001) 7.84%, hsla(0,0%,0%,0.686956378602) 15.1%, hsla(0,0%,0%,0.614177777772) 22.37%, hsla(0,0%,0%,0.527917695474) 30.26%, hsla(0,0%,0%,0.431193415614) 39.37%, hsla(0,0%,0%,0.32702222225399996) 50.3%, hsla(0,0%,0%,0.21842139914400005) 63.64%, hsla(0,0%,0%,0.10840823042399994) 80.01%, hsla(0,0%,0%,0) 100%);
		width: 100%;
    	height: 152px;
    	display: block;
    	position: absolute;
    	top: 0;
    	left: 0;
    	z-index: 1;
    }
    .logo {
    	position: relative;
    	z-index: 10;
    	a {
    		max-width: 160px;
	    	background-color: $white;
	    	border-radius: 0 0 5px 5px;
	    	padding: 12px;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
			display: inline-block;
		}
		img {
			margin: 0;
		}
    }
    .inner {
    	display: flex;
    	align-items: center;
    	justify-content: space-between;
    	position: relative;
    }
    .small-nav {
    	position: absolute;
    	top: calc(100% + 10px);
    	right: 10px;
    	left: 10px;
    	z-index: 30;
    	display: none;
    }
    .large-nav {
    	position: relative;
    	z-index: 30;
    	display: none;
    }
    .nav-toggle {
    	font-family: $alt-font;
    	font-weight: $alt-bold;
    	text-transform: uppercase;
    	display: flex;
    	align-items: center;
    	position: relative;
    	z-index: 30;
    	a {
    		color: #666;
    		padding-right: 5px;
    		&:hover, &:focus {
    			color: $brand;
    		}
    	}
    	&:hover a, &:focus a {
    		color: $brand;
    	}
    	&.active a {
    		display: none;
    	}
    }
}

@include breakpoint(800px) {
	.page-header {
		.small-nav,
		.nav-toggle {
			display: none;
		}
	    .large-nav {
	    	display: block;
	    }
	}
}

@include breakpoint(large) {
	.page-header {
		.logo {
			a {
				max-width: 100%;
				padding: 30px;
			}
		}
	}
}

/*------------------------------------*\
    @group Navigation
\*------------------------------------*/

.small-nav {
	background-color: rgba($brand, .9);
	font-family: $alt-font;
	border-radius: $border-radius;
	padding: 20px;
	text-align: center;
	a:not(.button) {
		color: $white;
		&:hover, &:focus {
			color: lighten($brand-alt, 30%);
		}
	}
	ul {
		font-size: rem-calc(20);
		font-weight: $main-bold;
		padding: 0;
		margin: 0 0 20px;
	}
	li {
		background: none;
		padding: 0;
		&::before {
			display: none;
		}
	}
	.top-level {
		padding: 10px 0;
	}
	.top-level > li {
		font-size: rem-calc(14);
		font-weight: $main-bold;
		text-transform: uppercase;
		a {
			padding: 7px 10px;
			display: inline-block;
			&:hover, &:focus {
				color: $brand;
			}
		}
		.arrow-icon {
			display: inline-block;
			float: right;
			padding: 7px 10px;
			.icon {
				color: $brand;
				&:hover {
					color: $brand-accent;
					cursor: pointer;
				}
			}
		}
		&.selected > a,
		&.selected > .arrow > a {
			color: $brand;
		}
	}
	.dropdown > ul {
		margin-bottom: 10px;
		display: none;
		> li {
			padding: 0;
			text-transform: none;
			a {
				font-size: rem-calc(16);
				font-weight: $alt-normal;
				padding: 2px 10px 2px 20px;
				&:hover, &:focus {
					color: $brand;
				}
			}
			&:first-child a {
				padding-top: 0;
			}
			&.selected a {
				color: $brand;
			}
		}
	}
}

.large-nav {
	ul {
		padding: 0;
		margin: 0;
		display: inline-block;
	}
	li {
		background: none;
		padding: 0;
		margin: 0 25px 0 0;
		display: inline-block;
		@include breakpoint(large) {
			margin-right: 40px;
		}
		a {
			font-weight: $main-bold;
			color: $white;
			padding: 0;
			text-transform: none;
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
			display: block;
			&:hover, &:focus {
				color: lighten($brand-alt, 30%);
			}
		}
		&::before {
			display: none;
		}
	}
}
/* @end Navigation */