.logo-section {
	background-color: $brand;
	padding: 30px 0;
	margin-bottom: $content-section-spacing-small;
	.inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	img {
		margin: 0;
	}
}
.logo-section-item:nth-child(2) {
	margin-right: 18px;
	margin-left: 18px;
}

@include breakpoint(medium) {
	.logo-section {
		padding: 50px 0;
		margin-bottom: $content-section-spacing;
	}
}