.contact-section {
	background-color: $brand;
	color: $white;
	padding: 50px 0 0;
	@include breakpoint(medium) {
		padding: 100px 0 0;
	}
	h2 {
		color: $white;
	}
	h3 {
		color: $white;
	}
	a {
		color: lighten($light-purple, 10%);
		&:hover, &:focus {
			color: $light-purple;
		}
	}
	.inner {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		grid-gap: 30px;
	}
}