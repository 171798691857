@include breakpoint(800px) {
	.testimonial-faqs {
		.inner {
			display: flex;
			justify-content: space-between;
		}
		.faqs {
			flex-basis: 57%;
			order: 1;
		}
		.testimonials {
			flex-basis: 40%;
		}
	}
}


.faqs {
	margin-bottom: 72px;
}


.testimonials {
	background-color: $tan;
	font-size: rem-calc(16);
	border-radius: $border-radius;
	padding: 20px 20px 24px;
	margin-top: 30px;
	@include breakpoint(medium) {
		padding: 30px 30px 36px;
	}
}

.testimonial-media {
	text-align: center;
	margin-top: -50px;
	margin-bottom: 30px;
	img {
		margin: 0;
		border-radius: 5px;
	}
}

.testimonial-text {
	background: url('/images/icons/quotes.png') left top no-repeat;
	max-width: 408px;
	padding-top: 24px;
	margin: 0 auto;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		background: url('/images/icons/quotes@2x.png') left top no-repeat;
		background-size: 81px 64px;
	}
	.speaker {
		text-align: right;
		margin-bottom: 0;
	}
}

/*------------------------------------*\
    @group FAQ accordion
\*------------------------------------*/

.accordion {
	margin-top: 14px;
}

.accordion-item {
	margin-bottom: 12px;
}

.accordion-title {
	font-weight: $main-bold;
	line-height: 1.25;
	padding-left: 20px;
	margin-bottom: 8px;
	&::before {
		font-size: 30px;
		font-weight: $main-normal;
		right: auto;
		left: 0;
		top: 0;
	}
}

.accordion-content {
	padding-left: 20px;
	padding-bottom: 20px;
}

.accordion-item.is-active > .accordion-title::before {
	font-size: 18px;
	top: 6px;
}

@include breakpoint(medium) {
	.accordion {
		margin-top: 28px;
	}

	.accordion-title {
		font-size: rem-calc(20);
		&::before {
			font-size: 30px;
			top: 2px;
		}
	}

	.accordion-content {
		font-size: rem-calc(20);
	}

	.accordion-item.is-active > .accordion-title::before {
		top: 7px;
	}
}

/* @end FAQ accordion */