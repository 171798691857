/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $white;
    padding: 50px 0 30px;
}

@include breakpoint(large) {
	.page-content {
	    padding: 100px 0 60px;
	}
}