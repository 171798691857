.icon-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 32px;
	margin-bottom: 50px;
}

.icon-grid-item {
	font-weight: $main-bold;
	display: flex;
	align-items: center;
	img {
		max-width: 44px;
		margin: 0 20px 0 0;
	}
	p {
		line-height: 1.25;
		margin: 0;
	}
}

@include breakpoint(900px) {
	.icon-grid-item {
		img {
			max-width: 69px;
			flex-shrink: 0;
		}
	}
}

@include breakpoint(1100px) {
	.icon-grid {
		grid-gap: 62px 40px;
		margin-bottom: 100px;
	}
}