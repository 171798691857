/*----------------------------------------------------------*\
    Slider

    All styles for sliders uses throughout the site are in
    this file.

\*----------------------------------------------------------*/


/*------------------------------------*\
    @group Hero Slider
\*------------------------------------*/
$slide-height: 500px;
.hero .slider {
	background: $dark-gray;
	min-height: $slide-height;
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
}
.hero .slider .slide-content {
	width: 100%;
	min-height: $slide-height;
	text-align: center;
	padding: 80px 65px 40px;
	margin: 0 auto;
	z-index: 50;
	.text {
		margin: 0 0 25px;
	}
	h2 {
		font-size: rem-calc(36);
		font-weight: 300;
		color: $white;
		text-shadow: $text-shadow;
		line-height: 1.05;
		margin: 0 0 20px;
	}
	h3 {
		font-family: $main-font;
		font-size: rem-calc(18);
		font-weight: normal;
		color: $white;
		text-shadow: $text-shadow;
		line-height: 1.5;
		margin: 0 0 20px;
	}
	.button {
		background: $brand;
		font-family: $heading-font;
		font-size: 24px;
		font-weight: 700;
		color: $white;
		line-height: 1.2;
		padding: 16px 39px;
		&:hover {
			 background: $black;
			 text-decoration: none;
		}
	}
}
.hero .slider .slick-arrow {
	width: 40px;
	height: 40px;
	background: $brand-trans;
	font-family: $icon-font;
	color: $brand;
	padding: 7px 14px 7px 10px;
	margin: 0;
	display: block;
	position: absolute;
	top: auto;
	bottom: 0;
	transform: translate(0, 0);
	z-index: 10;
	overflow: hidden;
	opacity: 1;
	transition: all .3s;
	&:hover {
		background-color: $black;
	}
	&::before,
	&::after {
		font-size: 28px;
		opacity: 1;
	}
	&.slick-prev  {
		left: 0;
		&::before {
			content: '\f009';
		}
	}
	&.slick-next {
		padding: 7px 10px 7px 14px;
		right: 0;
		&::before {
			content: '\f008';
		}
	}
}

/* Slide backgrounds */
.hero .slider .slide {
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	&.bg-desk {background-image: url('/images/slides/bg_desk.jpg');}
}

@media screen and #{breakpoint(medium)} {

	$slide-height: 500px;

	.hero .slider {
		height: $slide-height;
		.slide {
			height: $slide-height;
		}
	}
	.hero .slider .slide-content {
		max-width: 960px;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		.text > * {
			user-select: text;
		}
		h2 {
			font-size: rem-calc(44);
			display: inline-block;
		}
		h3 {
			font-size: rem-calc(32);
			display: inline-block;
		}
	}
}

@media only screen and (min-width: 46.9375em) {
	.hero .slider .slide {
		&.bg-desk {background: url('/images/slides/large/bg_desk.jpg') no-repeat center top;}
	}
}

@media only screen and (min-width: 65.125em) {
	.hero .slider .slick-arrow {
		top: 50%;
		transform: translate(0, -50%);
	}
}

/* @end */


.testimonial-text-slider {
	max-width: 408px;
	.slick-dots {
		margin-bottom: 0;
		display: flex;
		gap: 10px;
		justify-content: center;
		li {
			background: none;
			padding-left: 0;
			button {
				background: #9a9895;
				width: 20px;
				height: 20px;
				font-size: 0;
				cursor: pointer;;
				border-radius: 50%;
				padding: 0;
				display: block;
				transition: background-color .3s;
				&:hover, &:focus {
					background-color: $white;
				}
			}
			&.slick-active button {
				background-color: $white;
			}
		}
	}
}