/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	background-color: $brand-alt;
	font-size: rem-calc(20);
	font-weight: $main-bold;
	color: $white;
	padding: 10px 20px;
	border-radius: $border-radius;
	border: none;
	display: inline-block;
	line-height: 1;
	padding: 13px 15px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
	transition: background-color .3s;
	cursor: pointer;
	&:hover,
	&:focus {
		background-color: darken($brand-alt, 10%);
		color: $white;
		text-decoration: none;
	}
}
.button.small {
	font-size: rem-calc(14);
	padding: 8px 13px;
}
.button.large {
	font-size: rem-calc(24);
	padding: 18px 23px;
}
.button.short {
	padding-top: 8px;
	padding-bottom: 8px;
}
.button.alt {
	background: $brand-alt;
	color: $white;
	&:hover,
	&:focus {
		background: $brand-accent;
	}
}

.icon-link {
	font-weight: $main-bold;
	display: flex;
	align-items: center;
	img {
		max-height: 22px;
		margin: 0 10px 0 0;
	}
}

@include breakpoint(medium) {
	.icon-link {
		img {
			max-height: 100%;
		}
	}
}