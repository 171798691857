.special-olympics-section {
	background-color: $dark-tan;
	padding: 50px 0;
	h2 {
		margin-bottom: 20px;
	}
	p {
		margin-bottom: 0;
	}
	img {
		max-width: 262px;
	}
	a {
		color: #49008F;
		&:hover, &:focus {
			color: lighten(#49008F, 20%);
		}
	}
}

@include breakpoint(medium) {
	.special-olympics-section {
		font-size: rem-calc(18);
	}
}

@include breakpoint(large) {
	.special-olympics-section {
		img {
			max-width: 100%;
		}
	}
}