/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    background-color: $brand;
    font-size: rem-calc(16);
    color: $tan;
    padding: 30px 0;
    p {
        margin-bottom: 0;
    }
}