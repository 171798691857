$drag-color: #154c68;

.cocoen {
    box-sizing: border-box;
    cursor: pointer;
    line-height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    user-select: none;

    * {
        box-sizing: inherit;

        &::after,
        &::before {
            box-sizing: inherit;
        }
    }

    img,
    picture>img {
        max-width: none;
        margin-bottom: 0;
    }

    >img,
    >picture>img {
        display: block;
        width: 100%;
    }

    >div {

        &:first-child,
        picture & {
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 50%;
        }
    }

    &-drag {
        background: #2283b4;
        width: 4px;
        cursor: ew-resize;
        left: 50%;
        margin-left: -2px;
        position: absolute;
        bottom: 0;
        top: 0;
        &::before {
            content: '';
            background: url('/images/icons/left_carat.svg') $drag-color no-repeat center center;
            width: 20px;
            height: 28px;
            border-radius: 5px 0 0 5px;
            right: 50%;
            position: absolute;
            top: 50%;
        }

        &::after {
            content: '';
            background: url('/images/icons/right_carat.svg') $drag-color no-repeat center center;
            width: 20px;
            height: 28px;
            border-radius: 0 5px 5px 0;
            left: 50%;
            position: absolute;
            top: 50%;
        }
    }
}

.paint-compare {
    background-color: #dbd7cc;
    padding: 4px;
    border-radius: $border-radius;
    margin-bottom: 10px;
    @include breakpoint(medium) {
        padding: 10px;
        margin-bottom: 20px;
    }
}