/* raleway-regular - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/raleway-v14-latin-500.eot');
    /* IE9 Compat Modes */
    src: local('Raleway Medium'), local('Raleway-Medium'),
        url('/fonts/raleway-v14-latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/fonts/raleway-v14-latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/fonts/raleway-v14-latin-500.woff') format('woff'),
        /* Modern Browsers */
        url('/fonts/raleway-v14-latin-500.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/fonts/raleway-v14-latin-500.svg#Raleway') format('svg');
    /* Legacy iOS */
}

/* raleway-700 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/raleway-v14-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Raleway Bold'), local('Raleway-Bold'),
        url('/fonts/raleway-v14-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/fonts/raleway-v14-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/fonts/raleway-v14-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('/fonts/raleway-v14-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/fonts/raleway-v14-latin-700.svg#Raleway') format('svg');
    /* Legacy iOS */
}