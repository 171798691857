/*------------------------------------*\
    @group Hero
\*------------------------------------*/

.hero {
	background: url('/images/hero/bg_red_house.jpg') no-repeat left top;
	background-size: cover;
	height: 620px;
	display: flex;
	align-items: flex-end;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		background-image: url('/images/hero/bg_red_house@2x.jpg');
	}
	.inner {
		width: 100%;
	}
	.hero-content {
		background-color: rgba(21, 76, 104, 0.9);
		color: $white;
		padding: 20px;
		margin-left: -10px;
		margin-right: -10px;
		h1 {
			color: $white;
		}
		> *:last-child {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(medium) {
	.hero {
		background-image: url('/images/hero/bg_red_house_med.jpg');
		background-position: center top;
		height: 800px;
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('/images/hero/bg_red_house_med@2x.jpg');
		}
		.hero-content {
			max-width: 610px;
			margin: 0 0 50px;
		}
	}
}

@include breakpoint(large) {
	.hero {
		background-image: url('/images/hero/bg_red_house_lrg.jpg');
		background-position: center top;
		height: 1000px;
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('/images/hero/bg_red_house_lrg@2x.jpg');
		}
		.hero-content {
			margin-bottom: 100px;
		}
	}
}

//Loops over $hero-bg in _variables.scss and creates files with mixin
@each $name, $filename in $hero-bg {
  .#{$name} {
	@include hero-image($filename: $filename);
	background-color: map-get($hero-bg-color, $name);
  }
}

/* @end Hero */