.about-section {
	background-color: $tan;
	padding: 50px 0 0;
	.media {
		text-align: center;
		img {
			max-width: 300px;
			margin-bottom: 0;
		}
	}
}

@include breakpoint(large) {
	.about-section {
		font-size: rem-calc(18);
		.inner {
			display: flex;
			justify-content: space-between;
		}
		.media {
			margin-top: -120px;
			flex-basis: 40%;
			align-self: flex-end;
			img {
				max-width: 100%;
			}
		}
		.text {
			flex-basis: 57%;
			order: 1;
			> *:last-child {
				margin-bottom: 52px;
			}
		}
	}
}