.highlight-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	grid-gap: 40px;
}

.highlight-grid-item {
	max-width: 569px;
	margin: 0 auto;
	img {
		border-radius: $border-radius;
	}
}