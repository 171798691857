.slide-in-left {
  @include mui-slide(in, $direction: left, $duration: .3s);
}

.slide-out-right {
	@include mui-slide(out, $direction: right, $duration: .2s);
}

.hinge-in-right {
  @include mui-hinge(
	$state: in,
	$from: right,
	$turn-origin: from-back,
	$duration: 1s,
	$timing: easeInOut);
}

.hinge-out-right {
  @include mui-hinge(
	$state: out,
	$from: right,
	$turn-origin: from-back,
	$duration: 0.75s,
	$timing: easeInOut);
}

.hinge-in-top {
  @include mui-hinge(
	$state: in,
	$from: top,
	$turn-origin: from-back,
	$duration: 1s,
	$timing: easeInOut);
}

.hinge-out-top {
  @include mui-hinge(
	$state: out,
	$from: top,
	$turn-origin: from-back,
	$duration: 0.75s,
	$timing: easeInOut);
}